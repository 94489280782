import React, { useEffect, useState } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiFillShop } from "react-icons/ai";
import { TiCalculator } from "react-icons/ti";
import yash from "../assets/yash.svg";
import Card from "react-bootstrap/Card";
import { MdPhoneIphone } from "react-icons/md";
import { GrCreditCard } from "react-icons/gr";
import { FaLaptop } from "react-icons/fa";
import { BsWindow } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { GiShoppingCart } from "react-icons/gi";
import { MdOutlineLaptop } from "react-icons/md";
import { AiFillPlayCircle } from "react-icons/ai";
import { CgArrowTopRightR } from "react-icons/cg";
import { BsCardHeading } from "react-icons/bs";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { BsTextParagraph, BsShopWindow } from "react-icons/bs";
import axios from "axios";
import YouTube, { YouTubeProps } from "react-youtube";
import config from "../config";
import { useMediaQuery } from "react-responsive";

import { AiFillCloseCircle } from "react-icons/ai";

export default function LMS() {
  const [topicSelectYoutubePlaylist, setTopicSelectYoutubePlaylist] = useState(
    "Select"
  );

  const [buttonRetail, setButtonRetail] = useState(true);
  const [buttonPos, setButtonPos] = useState(false);
  const [buttonWebsite, setWebsite] = useState(false);
  const [buttonOnlineStore, setButtonOnlineStore] = useState(false);

  const [selectLanguage, setSelectLanguage] = useState("Select");
  const [selectProduct, setSelectProduct] = useState("Select");

  const [youtubeVideoId, setYoutubeVideoId] = useState("");
  const [youtubePlaylistId, setyoutubePlaylistId] = useState("");

  const [show, setShow] = useState(false);

  const [youtubePlayList, setYoutubePlayList] = useState([]);
  const [youtubePlayListVideo, setYoutubePlayListVideo] = useState([]);

  const handleSelectTopic = (e) => {
    console.log("");
    setTopicSelectYoutubePlaylist(e.target.value);
  };
  const handleSelectProduct = (e) => {
    setSelectProduct(e.target.value);
  };
  const handleSelectType = (e) => {
    setSelectLanguage(e.target.value);
  };

  React.useEffect(() => {
    const updateYoutubePlayList = async () => {
      await fetch(`${config.ApiGateWay}/youtubePlayList`, {
        method: "POST",
        body: JSON.stringify("yashvendra"),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("response", data);
        })
        .catch((err) => console.log(err));
    };
    return updateYoutubePlayList;
  }, []);

  useEffect(() => {
    getYoutubePlayListVideos();
  }, [topicSelectYoutubePlaylist]);

  const getYoutubePlayListVideos = async () => {
    // const youtubePlayListVideourl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=100&playlistId=${
    //   topicSelectYoutubePlaylist === "Select"
    //     ? "PLmHzPC3ZVJBp7mUAY4LOjqtavTIApy3Lk"
    //     : topicSelectYoutubePlaylist
    // }&key=${config.youtubeApiKey}`;

    const youtubePlayListVideourl = `https://prod-ghoshak.s3.ap-south-1.amazonaws.com/youtubePlayList/${
      topicSelectYoutubePlaylist === "Select"
        ? "PLmHzPC3ZVJBoOQHHH3xTbdBAOJPxUJqaF"
        : topicSelectYoutubePlaylist
    }.JSON`;

    await axios
      .get(youtubePlayListVideourl)
      .then(function(response) {
        console.log("res you pl ----", response);
        setYoutubePlayListVideo(response.data.items);
      })
      .catch(function(error) {
        console.log("error--- getYoutubePlayListVideos", error);
      })
      .finally(function() {});
  };
  useEffect(() => {
    getYoutubePlayList();
  }, []);
  const getYoutubePlayList = async () => {
    const youtubePlayLisUrl = `https://prod-ghoshak.s3.ap-south-1.amazonaws.com/youtubePlayList/listOfYoutubePlayList.JSON`;

    await axios
      .get(youtubePlayLisUrl)
      .then(function(response) {
        setYoutubePlayList(response.data.items);
        console.log("getYoutubeAllPlayList---", response);
      })
      .catch(function(error) {
        console.log("error--- getYoutubePlayList", error);
      })
      .finally(function() {});
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };
  const isMobile = useMediaQuery({ query: "(min-width: 600px)" });
  // const isTab = useMediaQuery({ query: "(max-width: 1024)" });

  // const opts = {
  //   height: isTab ? "400" : "600",
  //   width: isTab ? "600" : "1000",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 1,
  //   },
  // };

  const [
    youtubePlayListManagementFile,
    setyoutubePlayListManagementFile,
  ] = useState([]);
  const [publicVideoList, setPublicVideoList] = useState([]);
  const [privateVideoList, setPrivateVideoList] = useState([]);
  const [unlistedVideoList, setUnlistedVideoList] = useState([]);
  const [forShowingInLMSList, setForShowingInLMSVideoList] = useState([]);
  const [forNotShowingInLMSList, setForNotShowingInLMSVideoList] = useState([]);

  React.useEffect(() => {
    const getTesterData = () => {
      fetch(
        "https://payload-response.s3.ap-south-1.amazonaws.com/youtubePlayListManagementFile.json"
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setyoutubePlayListManagementFile(data);
          setPublicVideoList(data.Public);
          setPrivateVideoList(data.Private);
          setUnlistedVideoList(data.Unlisted);
          setForShowingInLMSVideoList(data.forShowingInLMS);
          setForNotShowingInLMSVideoList(data.forNotShowingInLMS);
          console.log("youtubePlayListManagementFile", data);
        });
    };

    return getTesterData;
  }, []);

  // const youtubePlayListFilterforShowingonly = [];
  // youtubePlayList.filter((data) => {
  //   console.log("forNotShowingInLMSList", forNotShowingInLMSList);
  //   forNotShowingInLMSList.map((dataforNotshowing) => {
  //     if (data.snippet.title !== dataforNotshowing.title) {
  //       youtubePlayListFilterforShowingonly.push(data);
  //     }
  //   });
  // });

  // console.log(
  //   "youtubePlayListFilterforShowingonly",
  //   youtubePlayListFilterforShowingonly
  // );

  /* <Card.Img variant="top" src={data.snippet.thumbnails.medium.url} />   {data.snippet.title} 
    
                <YouTube
                  videoId={data.snippet.resourceId.videoId}
                  opts={opts}
                  title="necjnencence ocnoncoenceonce"
                />
                  {String(data.snippet.description).substring(0, 70)} */

  return (
    <div>
      {show === true ? (
        <>
          <div className="youtubeplayer">
            <YouTube
              videoId={youtubeVideoId}
              className="video-container iframe"
            />
            <div
              className="closeButton"
              onClick={() => {
                setShow(false);
              }}
            >
              <AiFillCloseCircle className="CloseLogo" />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="lms-header" id="blogs">
        <div className="lms-header-top-margin"> </div>

        <div className="lms-header-flex">
          <div
            className="lms-margin-left"
            onClick={() => {
              setButtonRetail(true);
              setButtonPos(false);
              setWebsite(false);
              setButtonOnlineStore(false);
            }}
          >
            <AiFillShop
              style={{
                color: buttonRetail ? "rgb(234, 17, 17)" : "black",
              }}
              className="fillicon-shop"
            />
            <MdPhoneIphone
              style={{
                color: buttonRetail ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-icon-font-size"
            />
            <AiOutlineShoppingCart
              style={{
                color: buttonRetail ? "rgb(234, 17, 17)" : "black",
              }}
              className="cart"
            />
            <div
              className="retail-text"
              style={{
                color: buttonRetail ? "rgb(234, 17, 17)" : "black",
              }}
            >
              Retail
            </div>
          </div>
          <div
            className="lms-margin-left"
            onClick={() => {
              setButtonRetail(false);
              setButtonPos(true);
              setWebsite(false);
              setButtonOnlineStore(false);
            }}
          >
            <TiCalculator
              style={{
                color: buttonPos ? "rgb(234, 17, 17)" : "black",
              }}
              className="lmsicon-pos-inside"
            />
            <BsShopWindow
              style={{
                color: buttonPos ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-icon-pos "
            />
            <BsCardHeading
              style={{
                color: buttonPos ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-pos-card"
            />
            <div
              className="lms-text"
              style={{
                color: buttonPos ? "rgb(234, 17, 17)" : "black",
              }}
            >
              POS
            </div>
          </div>
          <div
            className="lms-margin-left"
            onClick={() => {
              setButtonRetail(false);
              setButtonPos(false);
              setWebsite(true);
              setButtonOnlineStore(false);
            }}
          >
            <BsWindow
              style={{
                color: buttonWebsite ? "rgb(234, 17, 17)" : "black",
              }}
              className="lmsicon-web-inside "
            />
            <FaLaptop
              style={{
                color: buttonWebsite ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-icon-web"
            />
            <TbWorld
              style={{
                color: buttonWebsite ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-web-world"
            />
            <div
              style={{
                color: buttonWebsite ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-text"
            >
              Website
            </div>
          </div>
          <div
            className="lms-margin-left"
            onClick={() => {
              setButtonRetail(false);
              setButtonPos(false);
              setWebsite(false);
              setButtonOnlineStore(true);
            }}
          >
            <BsTextParagraph
              style={{
                color: buttonOnlineStore ? "rgb(234, 17, 17)" : "black",
              }}
              className="lmsicon-onlinestore-inside"
            />
            <MdOutlineLaptop
              style={{
                color: buttonOnlineStore ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-icon-onlinestore"
            />
            <GiShoppingCart
              style={{
                color: buttonOnlineStore ? "rgb(234, 17, 17)" : "black",
              }}
              className="lms-onlinestore-cart"
            />
            <div
              className="lms-text"
              style={{
                color: buttonOnlineStore ? "rgb(234, 17, 17)" : "black",
              }}
            >
              Online Store
            </div>
          </div>
        </div>
        <div className="lms-select-flex">
          <div className="dropdown-margin">
            <div className="lms-drop-topic">Products</div>
            <select id="dropDown" onChange={(e) => handleSelectProduct(e)}>
              <option id="dropDownvalue" value="select">
                {selectProduct}
              </option>
              <option id="dropDownvalue" value="Retail">
                <div className="abc">Retail</div>
              </option>
              <option id="dropDownvalue" value="POS">
                POS
              </option>
              <option id="dropDownvalue" value="Website">
                Website
              </option>
              <option id="dropDownvalue" value="Online Store">
                Online Store
              </option>
            </select>
          </div>
          <div className="dropdown-margin">
            <div className="lms-drop-topic">Topics</div>
            <select id="dropDown" onChange={(e) => handleSelectTopic(e)}>
              <option id="dropDownvalue" value="select">
                Select
              </option>
              {youtubePlayList.map((data) => {
                return (
                  <option id={data.id} value={data.id}>
                    {data.snippet.title}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="dropdown-margin">
            <div className="lms-drop-topic">Language</div>
            <select id="dropDown" onChange={(e) => handleSelectType(e)}>
              <option id="dropDownvalue" value="select">
                {selectLanguage}
              </option>
              <option id="dropDownvalue" value="English">
                English
              </option>
              <option id="dropDownvalue" value="Hindi">
                Hindi
              </option>
              <option id="dropDownvalue" value="Tamil">
                Tamil
              </option>
            </select>
          </div>
        </div>
        <div className="lmd-body-content">
          {/* <div className="lms-body-card">
          <AiFillPlayCircle className="update-slider-palyIcon-body " />
          <CgArrowTopRightR className="update-slider-palyIcon-body-corner " />
          <Card className="update-slider-card">
            <div style={{ diplay: "flex" }}>
              <Card.Img variant="top" src={yash} />
            </div>
            <Card.Body>
              <Card.Title className="update-slider-card-text">
                Lorem ipsum dolor
              </Card.Title>
              <Card.Text className="update-slider-card-text-des ">
                Lorem ipsum dolor sit amet consectetur. Pellentesque a tristique
                nunc ornare amet id risus.
              </Card.Text>
            </Card.Body>
          </Card>
        </div> */}
          {youtubePlayListVideo.map((data) => {
            return (
              <div
                className="lms-body-card"
                onClick={() => {
                  setYoutubeVideoId(data.snippet.resourceId.videoId);
                  setShow(true);
                }}
              >
                <AiFillPlayCircle
                  onClick={() => {
                    setYoutubeVideoId(data.snippet.resourceId.videoId);
                    setShow(true);
                  }}
                  className="update-slider-palyIcon-body "
                />
                <CgArrowTopRightR className="update-slider-palyIcon-body-corner " />
                <Card className="update-slider-card">
                  <div style={{ diplay: "flex" }}>
                    <Card.Img
                      className="img-width"
                      variant="top"
                      src={data.snippet.thumbnails.medium.url}
                    />
                  </div>
                  <Card.Body>
                    <Card.Title className="update-slider-card-text">
                      {String(data.snippet.title).substring(0, 20) + "..."}
                    </Card.Title>
                    <Card.Text className="update-slider-card-text-des ">
                      {String(data.snippet.description).substring(0, 50) +
                        "..."}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

////backup

// import React, { useEffect, useState } from "react";
// import { AiOutlineShoppingCart } from "react-icons/ai";
// import { AiFillShop } from "react-icons/ai";
// import { TiCalculator } from "react-icons/ti";
// import yash from "../assets/yash.svg";
// import Card from "react-bootstrap/Card";
// import { MdPhoneIphone } from "react-icons/md";
// import { GrCreditCard } from "react-icons/gr";
// import { FaLaptop } from "react-icons/fa";
// import { BsWindow } from "react-icons/bs";
// import { TbWorld } from "react-icons/tb";
// import { GiShoppingCart } from "react-icons/gi";
// import { MdOutlineLaptop } from "react-icons/md";
// import { AiFillPlayCircle } from "react-icons/ai";
// import { CgArrowTopRightR } from "react-icons/cg";
// import Popup from "reactjs-popup";
// import "reactjs-popup/dist/index.css";
// import { BsTextParagraph, BsShopWindow } from "react-icons/bs";
// import axios from "axios";
// import YouTube, { YouTubeProps } from "react-youtube";

// import { AiFillCloseCircle } from "react-icons/ai";

// export default function LMS() {
//   const [topicSelectYoutubePlaylist, setTopicSelectYoutubePlaylist] = useState(
//     "Select"
//   );

//   const [youtubeVideoId, setYoutubeVideoId] = useState("");
//   const [youtubePlaylistId, setyoutubePlaylistId] = useState("");

//   const [show, setShow] = useState(false);

//   const [youtubePlayList, setYoutubePlayList] = useState([]);
//   const [youtubePlayListVideo, setYoutubePlayListVideo] = useState([]);

//   const handleSelectTopic = (e) => {
//     setTopicSelectYoutubePlaylist(e.target.value);
//   };
//   const handleSelectProduct = (e) => {
//     setTopicSelectYoutubePlaylist(e.target.value);
//   };
//   const handleSelectType = (e) => {
//     setTopicSelectYoutubePlaylist(e.target.value);
//   };

//   useEffect(() => {
//     getYoutubePlayListVideos();
//   }, [topicSelectYoutubePlaylist]);

//   const getYoutubePlayListVideos = async () => {
//     const youtubePlayListVideourl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=100&playlistId=${
//       topicSelectYoutubePlaylist === "Select"
//         ? "PLmHzPC3ZVJBp7mUAY4LOjqtavTIApy3Lk"
//         : topicSelectYoutubePlaylist
//     }&key=AIzaSyA0q5MvqeuK_V-4-aUUBP5L6XXdnH6XsFk`;

//     await axios
//       .get(youtubePlayListVideourl)
//       .then(function(response) {
//         console.log("res you pl ----", response);
//         setYoutubePlayListVideo(response.data.items);
//       })
//       .catch(function(error) {
//         console.log("error--- getYoutubePlayListVideos", error);
//       })
//       .finally(function() {});
//   };

//   useEffect(() => {
//     getYoutubePlayList();
//     getTesterData();
//   }, []);

//   const getYoutubePlayList = async () => {
//     const youtubePlayLisUrl =
//       "https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=UCaqtC5cZdeTXWYtLhwn2-RQ&maxResults=100&key=AIzaSyA0q5MvqeuK_V-4-aUUBP5L6XXdnH6XsFk";

//     await axios
//       .get(youtubePlayLisUrl)
//       .then(function(response) {
//         setYoutubePlayList(response.data.items);
//         console.log("getYoutubeAllPlayList---", response);
//       })
//       .catch(function(error) {
//         console.log("error--- getYoutubePlayList", error);
//       })
//       .finally(function() {});
//   };

//   const onPlayerReady = (event) => {
//     // access to player in all event handlers via event.target
//     event.target.pauseVideo();
//   };

//   const opts = {
//     height: "600",
//     width: "1000",
//     playerVars: {
//       // https://developers.google.com/youtube/player_parameters
//       autoplay: 1,
//     },
//   };

//   const [
//     youtubePlayListManagementFile,
//     setyoutubePlayListManagementFile,
//   ] = useState([]);
//   const [publicVideoList, setPublicVideoList] = useState([]);
//   const [privateVideoList, setPrivateVideoList] = useState([]);
//   const [unlistedVideoList, setUnlistedVideoList] = useState([]);
//   const [forShowingInLMSList, setForShowingInLMSVideoList] = useState([]);
//   const [forNotShowingInLMSList, setForNotShowingInLMSVideoList] = useState([]);

//   const getTesterData = () => {
//     fetch(
//       "https://payload-response.s3.ap-south-1.amazonaws.com/youtubePlayListManagementFile.json"
//     )
//       .then((response) => {
//         return response.json();
//       })
//       .then((data) => {
//         setyoutubePlayListManagementFile(data);
//         setPublicVideoList(data.Public);
//         setPrivateVideoList(data.Private);
//         setUnlistedVideoList(data.Unlisted);
//         setForShowingInLMSVideoList(data.forShowingInLMS);
//         setForNotShowingInLMSVideoList(data.forNotShowingInLMS);
//         console.log("youtubePlayListManagementFile", data);
//       });
//   };

//   // const youtubePlayListFilterforShowingonly = [];
//   // youtubePlayList.filter((data) => {
//   //   console.log("forNotShowingInLMSList", forNotShowingInLMSList);
//   //   forNotShowingInLMSList.map((dataforNotshowing) => {
//   //     if (data.snippet.title !== dataforNotshowing.title) {
//   //       youtubePlayListFilterforShowingonly.push(data);
//   //     }
//   //   });
//   // });

//   // console.log(
//   //   "youtubePlayListFilterforShowingonly",
//   //   youtubePlayListFilterforShowingonly
//   // );

//   /* <Card.Img variant="top" src={data.snippet.thumbnails.medium.url} />   {data.snippet.title}

//                 <YouTube
//                   videoId={data.snippet.resourceId.videoId}
//                   opts={opts}
//                   title="necjnencence ocnoncoenceonce"
//                 />
//                   {String(data.snippet.description).substring(0, 70)} */

//   return (
//     <div>
//       {show === true ? (
//         <>
//           <div className="youtubeplayer">
//             <YouTube videoId={youtubeVideoId} opts={opts} />
//             <div
//               className="closeButton"
//               onClick={() => {
//                 setShow(false);
//               }}
//             >
//               <AiFillCloseCircle className="CloseLogo" />
//             </div>
//           </div>
//         </>
//       ) : (
//         <></>
//       )}
//       <div className="lms-header" id="blogs">
//         <div style={{ height: "100px" }}> </div>
//         <div className="lms-header-flex">
//           <div className="lms-margin-left">
//             <AiFillShop className="fillicon-shop" />
//             <MdPhoneIphone className="lms-icon-font-size" />
//             <AiOutlineShoppingCart className="cart" />
//             <div className="retail-text">Retail</div>
//           </div>
//           <div className="lms-margin-left">
//             <TiCalculator className="lmsicon-pos-inside" />
//             <BsShopWindow className="lms-icon-pos " />
//             <GrCreditCard className="lms-pos-card" />
//             <div className="lms-text">POS</div>
//           </div>
//           <div className="lms-margin-left">
//             <BsWindow className="lmsicon-web-inside " />
//             <FaLaptop className="lms-icon-web" />
//             <TbWorld className="lms-web-world" />
//             <div className="lms-text">Website</div>
//           </div>
//           <div className="lms-margin-left">
//             <BsTextParagraph className="lmsicon-onlinestore-inside" />
//             <MdOutlineLaptop className="lms-icon-onlinestore" />
//             <GiShoppingCart className="lms-onlinestore-cart" />
//             <div className="lms-text">Online Store</div>
//           </div>
//         </div>
//         <div className="lms-select-flex">
//           <div className="dropdown-margin">
//             <div className="lms-drop-topic">Topics</div>
//             <select id="dropDown" onChange={(e) => handleSelectTopic(e)}>
//               <option id="dropDownvalue" value="select">
//                 Select
//               </option>
//               {youtubePlayList.map((data) => {
//                 return (
//                   <option id={data.id} value={data.id}>
//                     {data.snippet.title}
//                   </option>
//                 );
//               })}
//             </select>
//           </div>
//           <div className="dropdown-margin">
//             <div className="lms-drop-topic">Products</div>
//             <select id="dropDown" onChange={(e) => handleSelectProduct(e)}>
//               <option id="dropDownvalue" value="select">
//                 Select
//               </option>
//               <option id="dropDownvalue" value="select2">
//                 Select1
//               </option>
//               <option id="dropDownvalue" value="select3">
//                 Select2
//               </option>
//             </select>
//           </div>
//           <div className="dropdown-margin">
//             <div className="lms-drop-topic">Types</div>
//             <select id="dropDown" onChange={(e) => handleSelectType(e)}>
//               <option id="dropDownvalue" value="select">
//                 Select
//               </option>
//               <option id="dropDownvalue" value="select2">
//                 Select1
//               </option>
//               <option id="dropDownvalue" value="select3">
//                 Select2
//               </option>
//             </select>
//           </div>
//         </div>
//         <div className="lmd-body-content">
//           {/* <div className="lms-body-card">
//           <AiFillPlayCircle className="update-slider-palyIcon-body " />
//           <CgArrowTopRightR className="update-slider-palyIcon-body-corner " />
//           <Card className="update-slider-card">
//             <div style={{ diplay: "flex" }}>
//               <Card.Img variant="top" src={yash} />
//             </div>
//             <Card.Body>
//               <Card.Title className="update-slider-card-text">
//                 Lorem ipsum dolor
//               </Card.Title>
//               <Card.Text className="update-slider-card-text-des ">
//                 Lorem ipsum dolor sit amet consectetur. Pellentesque a tristique
//                 nunc ornare amet id risus.
//               </Card.Text>
//             </Card.Body>
//           </Card>
//         </div> */}
//           {youtubePlayListVideo.map((data) => {
//             return (
//               <div
//                 className="lms-body-card"
//                 onClick={() => {
//                   setYoutubeVideoId(data.snippet.resourceId.videoId);
//                   setShow(true);
//                 }}
//               >
//                 <AiFillPlayCircle
//                   onClick={() => {
//                     setYoutubeVideoId(data.snippet.resourceId.videoId);
//                     setShow(true);
//                   }}
//                   className="update-slider-palyIcon-body "
//                 />
//                 <CgArrowTopRightR className="update-slider-palyIcon-body-corner " />
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img
//                       className="img-width"
//                       variant="top"
//                       src={data.snippet.thumbnails.medium.url}
//                     />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       {String(data.snippet.title).substring(0, 20) + "..."}
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       {String(data.snippet.description).substring(0, 50) +
//                         "..."}
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// }

// const [topicSelectYoutubePlaylist, setTopicSelectYoutubePlaylist] = useState(
//   "Select"
// );

// const [buttonRetail, setButtonRetail] = useState(true);
// const [buttonPos, setButtonPos] = useState(false);
// const [buttonWebsite, setWebsite] = useState(false);
// const [buttonOnlineStore, setButtonOnlineStore] = useState(false);

// const [selectLanguage, setSelectLanguage] = useState("Select");
// const [selectProduct, setSelectProduct] = useState("Select");

// const [youtubeVideoId, setYoutubeVideoId] = useState("");
// const [youtubePlaylistId, setyoutubePlaylistId] = useState("");

// const [show, setShow] = useState(false);

// const [youtubePlayList, setYoutubePlayList] = useState([]);
// const [youtubePlayListVideo, setYoutubePlayListVideo] = useState([]);

// const handleSelectTopic = (e) => {
//   setTopicSelectYoutubePlaylist(e.target.value);
// };
// const handleSelectProduct = (e) => {
//   setSelectProduct(e.target.value);
// };
// const handleSelectType = (e) => {
//   setSelectLanguage(e.target.value);
// };

// useEffect(() => {
//   updateYoutubePlayList();
// }, []);
// const updateYoutubePlayList = async () => {
//   await fetch(`${config.ApiGateWay}/youtubePlayList`, {
//     method: "POST",
//     body: JSON.stringify("yashvendra"),
//     headers: {
//       "Content-Type": "application/json",
//     },
//   })
//     .then((res) => res.json())
//     .then((data) => {
//       console.log("response", data);
//     })
//     .catch((err) => console.log(err));
// };

// React.useEffect(() => {
//   const getYoutubePlayListVideos = async () => {
// const youtubePlayListVideourl = `https://prod-ghoshak.s3.ap-south-1.amazonaws.com/youtubePlayList/${
//   topicSelectYoutubePlaylist === "Select"
//     ? "PLmHzPC3ZVJBoOQHHH3xTbdBAOJPxUJqaF"
//     : topicSelectYoutubePlaylist
// }.JSON`;
//     // const youtubePlayListVideourl = `https://prod-ghoshak.s3.ap-south-1.amazonaws.com/youtubePlayList/${topicSelectYoutubePlaylist}.JSON`;

//     // const youtubePlayListVideourlLatest =
//     //   "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/youtubePlayList/listOfYoutubePlayListLatest.JSON";

//     await axios
//       .get(youtubePlayListVideourl)
//       .then(function(response) {
//         console.log("res you pl ----", response);
//         setYoutubePlayListVideo(response.data.items);
//       })
//       .catch(function(error) {
//         console.log("error--- getYoutubePlayListVideos", error);
//       })
//       .finally(function() {});
//   };
//   return getYoutubePlayListVideos;
// }, [topicSelectYoutubePlaylist]);

// React.useEffect(() => {
//   const getYoutubePlayList = async () => {
//     const youtubePlayLisUrl = `https://prod-ghoshak.s3.ap-south-1.amazonaws.com/youtubePlayList/listOfYoutubePlayList.JSON`;

//     await axios
//       .get(youtubePlayLisUrl)
//       .then(function(response) {
//         setYoutubePlayList(response.data.items);
//         console.log("getYoutubeAllPlayList---", response);
//       })
//       .catch(function(error) {
//         console.log("error--- getYoutubePlayList", error);
//       })
//       .finally(function() {});
//   };

//   return getYoutubePlayList;
// }, []);

// // React.useEffect(() => {
// //   const getYoutubePlayList = async () => {
// //     const youtubePlayLisUrl = `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet%2CcontentDetails&channelId=${config.youtubeApiChannelID}&maxResults=100&key=${config.youtubeApiKey}`;

// //     await axios
// //       .get(youtubePlayLisUrl)
// //       .then(function(response) {
// //         setYoutubePlayList(response.data.items);
// //         console.log("getYoutubeAllPlayList---", response);
// //       })
// //       .catch(function(error) {
// //         console.log("error--- getYoutubePlayList", error);
// //       })
// //       .finally(function() {});
// //   };

// //   return getYoutubePlayList;
// // }, []);

// const onPlayerReady = (event) => {
//   // access to player in all event handlers via event.target
//   event.target.pauseVideo();
// };
// const isMobile = useMediaQuery({ query: "(min-width: 600px)" });
// // const isTab = useMediaQuery({ query: "(max-width: 1024)" });

// // const opts = {
// //   height: isTab ? "400" : "600",
// //   width: isTab ? "600" : "1000",
// //   playerVars: {
// //     // https://developers.google.com/youtube/player_parameters
// //     autoplay: 1,
// //   },
// // };

// const [
//   youtubePlayListManagementFile,
//   setyoutubePlayListManagementFile,
// ] = useState([]);
// const [publicVideoList, setPublicVideoList] = useState([]);
// const [privateVideoList, setPrivateVideoList] = useState([]);
// const [unlistedVideoList, setUnlistedVideoList] = useState([]);
// const [forShowingInLMSList, setForShowingInLMSVideoList] = useState([]);
// const [forNotShowingInLMSList, setForNotShowingInLMSVideoList] = useState([]);

// React.useEffect(() => {
//   const getTesterData = () => {
//     fetch(
//       "https://payload-response.s3.ap-south-1.amazonaws.com/youtubePlayListManagementFile.json"
//     )
//       .then((response) => {
//         return response.json();
//       })
//       .then((data) => {
//         setyoutubePlayListManagementFile(data);
//         setPublicVideoList(data.Public);
//         setPrivateVideoList(data.Private);
//         setUnlistedVideoList(data.Unlisted);
//         setForShowingInLMSVideoList(data.forShowingInLMS);
//         setForNotShowingInLMSVideoList(data.forNotShowingInLMS);
//         console.log("youtubePlayListManagementFile", data);
//       });
//   };

//   return getTesterData;
// }, []);
