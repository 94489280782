import ReactDOM, { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Header from "../src/components/header";
import Footer from "../src/components/footer";
import FQS from "../src/components/fqs";
import LMS from "../src/components/lms";
import NewUpdateVideos from "../src/components/newUpdateVideos";
import React from "react";
import HomePage from "./components/homePage";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="Header" element={<Header />} />
        <Route path="NewUpdateVideos" element={<NewUpdateVideos />} />
        <Route path="LMS" element={<LMS />} />
        <Route path="FQS" element={<FQS />} />
        <Route path="Footer" element={<Footer />} />
      </Routes>
    </BrowserRouter>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// import React from "react";

// import "./App.css";

// import Header from "../src/components/header";
// import Footer from "../src/components/footer";
// import FQS from "../src/components/fqs";
// import LMS from "../src/components/lms";
// import NewUpdateVideos from "../src/components/newUpdateVideos";

// function App() {
//   return (
//     <div>
//       <Header />

//       <NewUpdateVideos />
//       <LMS />
//       <FQS />
//       <Footer />
//     </div>
//   );
// }

// export default App;
