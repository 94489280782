import React from "react";

import Header from "./header";
import Footer from "./footer";
import FQS from "./fqs";
import LMS from "./lms";
import NewUpdateVideos from "./newUpdateVideos";

function HomePage() {
  return (
    <div>
      <Header />
      <NewUpdateVideos />
      <LMS />
      <FQS />
      <Footer />
    </div>
  );
}

export default HomePage;

// import dependencies

// import React from "react";

// import { Outlet, Link } from "react-router-dom";

// const HomePage = () => {
//   return (
//     <>
//       <h1>wncoencneo j ceocoeco</h1>
//       <nav>
//         <Link to="/Footer">Footer</Link>
//         <Link to="/FQS">FQS</Link>
//         <Link to="/LMS">LMS</Link>
//         <Link to="/NewUpdateVideos">NewUpdateVideos</Link>
//       </nav>
//       <Outlet />
//     </>
//   );
// };

// export default HomePage;
