//https://www.npmjs.com/package/react-slick-slider
import { AiFillPlayCircle } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import { useMediaQuery } from "react-responsive";
import { Image } from "antd";
import { AiFillCloseCircle } from "react-icons/ai";
import Slider from "react-slick";
import axios from "axios";
import love from "../assets/love.png";
import yash from "../assets/yash.svg";
import yash2 from "../assets/yash2.png";

import update from "../assets/updatev.svg";
import backdemo from "../assets/backdemo.png";
import Button from "react-bootstrap/Button";
import { CgArrowTopRightR } from "react-icons/cg";
import Card from "react-bootstrap/Card";
import config from "../config";

export default function NewUpdateVideos() {
  const [show, setShow] = useState(false);
  const [youtubeVideoId, setYoutubeVideoId] = useState("");
  const [youtubePlayListVideo, setYoutubePlayListVideo] = useState([]);
  React.useEffect(() => {
    const getYoutubePlayListVideos = async () => {
      // const youtubePlayListVideourl = `https://www.googleapis.com/youtube/v3/search?key=${config.youtubeApiKey}&channelId=${config.youtubeApiChannelID}&part=snippet,id&order=date&maxResults=50`;
      const youtubePlayListVideourl =
        "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/youtubePlayList/listOfYoutubePlayListLatest.JSON";
      await axios
        .get(youtubePlayListVideourl)
        .then(function(response) {
          console.log("latest----", response);
          setYoutubePlayListVideo(response.data.items);
        })
        .catch(function(error) {
          console.log("error---", error);
        })
        .finally(function() {});
    };
    return getYoutubePlayListVideos;
  }, []);

  //

  // const getYoutubePlayListVideos = async () => {
  //   const youtubePlayListVideourl = `https://www.googleapis.com/youtube/v3/search?key=AIzaSyA0q5MvqeuK_V-4-aUUBP5L6XXdnH6XsFk&channelId=UCaqtC5cZdeTXWYtLhwn2-RQ&part=snippet,id&order=date&maxResults=50`;

  //   await axios
  //     .get(youtubePlayListVideourl)
  //     .then(function(response) {
  //       console.log("latest----", response);
  //       setYoutubePlayListVideo(response.data.items);
  //     })
  //     .catch(function(error) {
  //       console.log("error---", error);
  //     })
  //     .finally(function() {});
  // };
  const isMobile = useMediaQuery({ query: "(min-width: 600px)" });
  const isTab = useMediaQuery({ query: "(min-width: 1024)" });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 3 : 1,
    slidesToScroll: isMobile ? 3 : 1,
    dots: false,
    centerMode: true,
    // autoplay: true,
  };

  const opts = {
    height: !isTab ? "400" : "600",
    width: !isTab ? "600" : "1000",
    height: "600",
    width: "1000",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  // const opts = {
  //   height: "600",
  //   width: "1000",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 1,
  //   },
  // };
  return (
    <div
      id="newdemovideos"
      className="demoyoutube"
      style={{
        backgroundImage: `url(${backdemo})`,
      }}
    >
      {show === true ? (
        <>
          <div className="youtubeplayer">
            <YouTube
              videoId={youtubeVideoId}
              className="video-container iframe"
            />
            <div
              className="closeButton"
              onClick={() => {
                setShow(false);
              }}
            >
              <AiFillCloseCircle className="CloseLogo" />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <img src={backdemo}></img> */}
      <div style={{ height: "40px" }}></div>
      <div className="update-title">New Update Videos</div>
      <div className="padding-eft">
        <div className="update-slider-margin">
          <div className="update-slider-box">
            <Slider {...settings}>
              {/* <div className="update-slider-card-box">
                <Card className="update-slider-card">
                  <div style={{ diplay: "flex" }}>
                    <Card.Img variant="top" src={yash} />
                    <AiFillPlayCircle className="update-slider-palyIcon " />
                  </div>
                  <Card.Body>
                    <Card.Title className="update-slider-card-text">
                      Lorem ipsum dolor
                    </Card.Title>
                    <Card.Text className="update-slider-card-text-des ">
                      Lorem ipsum dolor sit amet consectetur. Pellentesque a
                      tristique nunc ornare amet id risus.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div> */}
              {youtubePlayListVideo.map((data) => {
                return (
                  <div className="update-slider-card-box">
                    <Card
                      className="update-slider-card-video"
                      onClick={() => {
                        setYoutubeVideoId(data.id.videoId);
                        setShow(true);
                      }}
                    >
                      <div style={{ diplay: "flex" }}>
                        <Card.Img
                          className="img-width"
                          variant="top"
                          src={data.snippet.thumbnails.medium.url}
                        />
                        <AiFillPlayCircle
                          onClick={() => {
                            setYoutubeVideoId(data.id.videoId);
                            setShow(true);
                          }}
                          className="update-slider-palyIcon-demo"
                        />
                      </div>
                      <Card.Body>
                        <Card.Title className="update-slider-card-text-demo">
                          {String(data.snippet.title).substring(0, 20) + "..."}
                        </Card.Title>
                        <Card.Text className="update-slider-card-text-des-demo ">
                          {String(data.snippet.description).substring(0, 80) +
                            "..."}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>

                  // <div className="lms-body-card">
                  //   <AiFillPlayCircle className="update-slider-palyIcon-body " />
                  //   <CgArrowTopRightR className="update-slider-palyIcon-body-corner " />
                  //   <Card className="update-slider-card">
                  //     <div style={{ diplay: "flex" }}>
                  // <Card.Img
                  //   className="img-width"
                  //   variant="top"
                  //   src={data.snippet.thumbnails.medium.url}
                  // />
                  //     </div>
                  //     <Card.Body>
                  //       <Card.Title className="update-slider-card-text">
                  //         {String(data.snippet.title).substring(0, 20) + "..."}
                  //       </Card.Title>
                  //       <Card.Text className="update-slider-card-text-des ">
                  // {String(data.snippet.description).substring(0, 50) +
                  //   "..."}
                  //       </Card.Text>
                  //     </Card.Body>
                  //   </Card>
                  // </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import update from "../assets/updatev.svg";

// export default function NewUpdateVideos() {
//   return (
//     <div>

//  <div className="update">
//   <img src={update}></img>
// </div>

//     </div>
//   );
// }

// //https://www.npmjs.com/package/react-slick-slider
// import { AiFillPlayCircle } from "react-icons/ai";
// import React from "react";
// import Slider from "react-slick";

// import love from "../assets/love.png";
// import yash from "../assets/yash.svg";
// import yash2 from "../assets/yash2.png";

// import update from "../assets/updatev.svg";
// import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";

// export default function NewUpdateVideos() {
//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     dots: false,

//     centerMode: true,
//     // autoplay: true,

//     // afterChange: () =>
//     //   setState((state) => ({ updateCount: state.updateCount + 1 })),
//     // beforeChange: (current, next) => setState({ slideIndex: next }),
//   };
//   return (
//     <div>
//       <div className="update">
//         <img src={update}></img>
//       </div>
//       <div>
//         <div className="update-title" id="newdemovideos">
//           New Update Videos
//         </div>
//         <div className="update-slider-margin">
//           <div className="update-slider-box">
//             <Slider {...settings}>
//               <div className="update-slider-card-box">
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img variant="top" src={yash} />
//                     <AiFillPlayCircle className="update-slider-palyIcon " />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       Lorem ipsum dolor
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       Lorem ipsum dolor sit amet consectetur. Pellentesque a
//                       tristique nunc ornare amet id risus.
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>

//               <div className="update-slider-card-box">
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img variant="top" src={yash} />
//                     <AiFillPlayCircle className="update-slider-palyIcon " />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       Lorem ipsum dolor
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       Lorem ipsum dolor sit amet consectetur. Pellentesque a
//                       tristique nunc ornare amet id risus.
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>
//               <div className="update-slider-card-box">
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img variant="top" src={yash} />
//                     <AiFillPlayCircle className="update-slider-palyIcon " />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       Lorem ipsum dolor
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       Lorem ipsum dolor sit amet consectetur. Pellentesque a
//                       tristique nunc ornare amet id risus.
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>
//               <div className="update-slider-card-box">
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img variant="top" src={yash} />
//                     <AiFillPlayCircle className="update-slider-palyIcon " />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       Lorem ipsum dolor
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       Lorem ipsum dolor sit amet consectetur. Pellentesque a
//                       tristique nunc ornare amet id risus.
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>
//               <div className="update-slider-card-box">
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img variant="top" src={yash} />
//                     <AiFillPlayCircle className="update-slider-palyIcon " />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       Lorem ipsum dolor
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       Lorem ipsum dolor sit amet consectetur. Pellentesque a
//                       tristique nunc ornare amet id risus.
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>
//               <div className="update-slider-card-box">
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img variant="top" src={yash} />
//                     <AiFillPlayCircle className="update-slider-palyIcon " />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       Lorem ipsum dolor
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       Lorem ipsum dolor sit amet consectetur. Pellentesque a
//                       tristique nunc ornare amet id risus.
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>
//               <div className="update-slider-card-box">
//                 <Card className="update-slider-card">
//                   <div style={{ diplay: "flex" }}>
//                     <Card.Img variant="top" src={yash} />
//                     <AiFillPlayCircle className="update-slider-palyIcon " />
//                   </div>
//                   <Card.Body>
//                     <Card.Title className="update-slider-card-text">
//                       Lorem ipsum dolor
//                     </Card.Title>
//                     <Card.Text className="update-slider-card-text-des ">
//                       Lorem ipsum dolor sit amet consectetur. Pellentesque a
//                       tristique nunc ornare amet id risus.
//                     </Card.Text>
//                   </Card.Body>
//                 </Card>
//               </div>
//             </Slider>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// // import React from "react";
// // import update from "../assets/updatev.svg";

// // export default function NewUpdateVideos() {
// //   return (
// //     <div>

// //  <div className="update">
// //   <img src={update}></img>
// // </div>

// //     </div>
// //   );
// // }
