import React from "react";
import logo from "../assets/logo.svg";

import { IoLogoGooglePlaystore } from "react-icons/io5";
import { BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaWhatsappSquare } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { FaTwitterSquare } from "react-icons/fa";
import { Col, Row } from "antd";

export default function Footer() {
  return (
    <div>
      <Row className="footer" id="aboutus">
        <Col span={8}>
          {" "}
          <div className="footer-contact">
            <div
              className="footer-text"
              onClick={() => {
                window.open("support@ghoshak.com");
              }}
            >
              support@ghoshak.com
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.google.com/maps/place/Zoryboard+Software+Solutions+Private+Limited/@13.061025,80.2454077,17z/data=!4m5!3m4!1s0x3a52676c26a1032d:0xdd6653a5f56f5654!8m2!3d13.061025!4d80.2475964"
                );
              }}
              className="footer-text-add"
            >
              Eldorado Building, 7A, Nungambakkam High Rd, Thousand Lights West,
              Thousand Lights, Chennai, Tamil Nadu 600034.
            </div>
          </div>{" "}
        </Col>
        <Col span={8}>
          {" "}
          <div>
            <div
              className="footer-mid-text"
              onClick={() => {
                window.open("https://ghoshak.com/");
              }}
            >
              More Information
            </div>
            <div
              onClick={() => {
                window.open("https://ghoshak.com/#features");
              }}
              className="footer-mid-text"
            >
              Product
            </div>
            <div
              onClick={() => {
                window.open("https://ghoshak.com/#designs");
              }}
              className="footer-mid-text"
            >
              Services
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
                );
              }}
              className="footer-mid-text"
            >
              Pricing
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
                );
              }}
              className="footer-mid-text"
            >
              FAQ
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div>
            <div className="footer-sm">
              <div
                className="footer-logo"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.ghoshak_owner&hl=en_IN&gl=US"
                  );
                }}
              >
                <img src={logo}></img>
                <div className="footer-playstore">
                  <IoLogoGooglePlaystore className="playstore" />
                  <div className="footer-play-test-get">
                    Get It on Googleplay
                  </div>
                </div>
              </div>

              <div className="footer-play-bottom">
                <BsFacebook
                  onClick={() => {
                    window.open("https://m.facebook.com/ghoshakindia");
                  }}
                  className="footer-social-icons"
                />
                <AiFillInstagram
                  onClick={() => {
                    window.open("https://m.facebook.com/ghoshakindia");
                  }}
                  className="footer-social-icons"
                />
                <FaWhatsappSquare
                  onClick={() => {
                    window.open(
                      "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
                    );
                  }}
                  className="footer-social-icons"
                />
                <BsYoutube
                  onClick={() => {
                    window.open(
                      "https://www.youtube.com/channel/UCaqtC5cZdeTXWYtLhwn2-RQ"
                    );
                  }}
                  className="footer-social-icons"
                />
                <FaTwitterSquare
                  onClick={() => {
                    window.open("https://mobile.twitter.com/ghoshakindia");
                  }}
                  className="footer-social-icons"
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* <div className="footer" id="aboutus">
       
      </div> */}
    </div>
  );
}

// import React from "react";
// import logo from "../assets/logo.svg";

// import { IoLogoGooglePlaystore } from "react-icons/io5";
// import { BsFacebook } from "react-icons/bs";
// import { AiFillInstagram } from "react-icons/ai";
// import { FaWhatsappSquare } from "react-icons/fa";
// import { BsYoutube } from "react-icons/bs";
// import { FaTwitterSquare } from "react-icons/fa";

// export default function Footer() {
//   return (

//     <div>
//       <div className="footer" id="aboutus">
//         <div className="footer-contact">
//           <div
//             className="footer-text"
//             onClick={() => {
//               window.open("support@ghoshak.com");
//             }}
//           >
//             support@ghoshak.com
//           </div>
//           <div
//             onClick={() => {
//               window.open(
//                 "https://www.google.com/maps/place/Zoryboard+Software+Solutions+Private+Limited/@13.061025,80.2454077,17z/data=!4m5!3m4!1s0x3a52676c26a1032d:0xdd6653a5f56f5654!8m2!3d13.061025!4d80.2475964"
//               );
//             }}
//             className="footer-text-add"
//           >
//             Eldorado Building, 7A, Nungambakkam High Rd, Thousand Lights West,
//             Thousand Lights, Chennai, Tamil Nadu 600034.
//           </div>
//         </div>
//         <div className="footer-contact">
//           <div
//             className="footer-mid"
//             onClick={() => {
//               window.open("https://ghoshak.com/");
//             }}
//           >
//             More Information
//           </div>
//           <div
//             onClick={() => {
//               window.open("https://ghoshak.com/#features");
//             }}
//             className="footer-mid-text"
//           >
//             Product
//           </div>
//           <div
//             onClick={() => {
//               window.open("https://ghoshak.com/#designs");
//             }}
//             className="footer-mid-text"
//           >
//             Services
//           </div>
//           <div
//             onClick={() => {
//               window.open(
//                 "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
//               );
//             }}
//             className="footer-mid-text"
//           >
//             Pricing
//           </div>
//           <div
//             onClick={() => {
//               window.open(
//                 "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
//               );
//             }}
//             className="footer-mid-text"
//           >
//             FAQ
//           </div>
//         </div>
//         <div className="footer-contact">
//           <div className="footer-sm">
//             <div
//               className="footer-logo"
//               onClick={() => {
//                 window.open(
//                   "https://play.google.com/store/apps/details?id=com.ghoshak_owner&hl=en_IN&gl=US"
//                 );
//               }}
//             >
//               <img src={logo}></img>
//               <div className="footer-playstore">
//                 <IoLogoGooglePlaystore className="playstore" />
//                 <div className="footer-play-test-get">Get It on Googleplay</div>
//               </div>
//             </div>

//             <div className="footer-play-bottom">
//               <BsFacebook
//                 onClick={() => {
//                   window.open("https://m.facebook.com/ghoshakindia");
//                 }}
//                 className="footer-social-icons"
//               />
//               <AiFillInstagram
//                 onClick={() => {
//                   window.open("https://m.facebook.com/ghoshakindia");
//                 }}
//                 className="footer-social-icons"
//               />
//               <FaWhatsappSquare
//                 onClick={() => {
//                   window.open(
//                     "https://api.whatsapp.com/send/?phone=917401066066&text&app_absent=0"
//                   );
//                 }}
//                 className="footer-social-icons"
//               />
//               <BsYoutube
//                 onClick={() => {
//                   window.open(
//                     "https://www.youtube.com/channel/UCaqtC5cZdeTXWYtLhwn2-RQ"
//                   );
//                 }}
//                 className="footer-social-icons"
//               />
//               <FaTwitterSquare
//                 onClick={() => {
//                   window.open("https://mobile.twitter.com/ghoshakindia");
//                 }}
//                 className="footer-social-icons"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
