import logo from "../assets/logo.svg";
import lms1 from "../assets/lms1.png";
import lms2 from "../assets/lms2.png";
import lms3 from "../assets/lms3.png";
import lms4 from "../assets/lms4.png";
import { AiOutlineGlobal } from "react-icons/ai";
import { FiMenu } from "react-icons/fi";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Row, Col } from "antd";

import { useNavigate } from "react-router-dom";

import { Button, Drawer, Radio, Space } from "antd";

export default function Header() {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");

  const showDrawer = () => {
    setOpen(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  return (
    <div>
      <Drawer
        title="Ghoshak Traning"
        placement={placement}
        width={500}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
          </Space>
        }
      >
        <div>
          <a href="#home" onClick={onClose}>
            Home
          </a>
        </div>
        <div>
          <a href="#newdemovideos" onClick={onClose}>
            Latest Videos
          </a>{" "}
        </div>
        <div>
          <a href="#blogs" onClick={onClose}>
            Blogs
          </a>
        </div>
        <div>
          <a href="#newdemovideos" onClick={onClose}>
            Latest Videos
          </a>{" "}
        </div>
        <div>
          <a href="#fqs" onClick={onClose}>
            FAQ
          </a>
        </div>
        <div>
          <a href="#aboutus" onClick={onClose}>
            About Us
          </a>
        </div>
      </Drawer>

      <div className="header" id="#home">
        <Row>
          <Col span={4}>
            <img className="header-logo" src={logo}></img>
          </Col>
          <Col span={14}>
            <div className="header-text-margin">
              <div className="header-text-bold"> Home </div>

              <a href="#newdemovideos" className="header-text-bold-demo">
                Latest Videos
              </a>
              <a href="#blogs" className="header-text-bold-blogs">
                Blogs
              </a>
              <a href="#fqs" className="header-text-bold-fqs">
                FAQ
              </a>
              <a href="#aboutus" className="header-text-bold-about">
                About Us
              </a>
            </div>
          </Col>
          <Col span={6}>
            <div className="header-language">Language</div>
            <div className="menu-icon">
              <AiOutlineGlobal className="header-icon" />
              <FiMenu
                onClick={() => {
                  showDrawer();
                }}
                className="header-icon-menu"
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="carousel-margin">
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
        >
          <img src={lms1} />
          <img src={lms2} />
          <img src={lms3} />
          <img src={lms4} />
        </Carousel>
        <div className="header-caousel-text">Demo Videos / Training Videos</div>
        <div className="header-caousel-text-loren">
          Lorem ipsum dolor sit amet consectetur. Pellentesque a tristique nunc
          ornare amet id risus. Nam euismod tellus convallis risus rhoncus
          massa. Faucibus adipiscing
        </div>
      </div>
    </div>
  );
}
