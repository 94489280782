export default {
  youtubeApiKey: "A JWM2xZk",
  youtubeApiChannelID: "UCaqtC5cZdeTXWYtLhwn2-RQ",
  ApiGateWay: "https://oh8p0g9ia4.execute-api.ap-south-1.amazonaws.com/dev/",
};
//https://8jj94le6tb.execute-api.ap-south-1.amazonaws.com/dev/lambda/getTaskUpdate

// "AIzaSyA0q5MvqeuK_V-4-aUUBP5L6XXdnH6XsFk" previous key
//youtubeApiChannelID: "UCaqtC5cZdeTXWYtLhwn2-RQ",
//AIzaSyBCyRVCjOTimHYvaPI_RkMx0IGOJWM2xZk  latest key
