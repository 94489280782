import React from "react";
import { IoMdAdd } from "react-icons/io";
import { GrSubtract } from "react-icons/gr";
import { Collapse } from "antd";
const { Panel } = Collapse;

const onChange = (key) => {
  console.log(key);
};
export default function FQS() {
  return (
    <div className="fqs" id="fqs">
      <div className="fqs-title">FAQ’s</div>

      <div className="fqs-header-margin">
        <Collapse defaultActiveKey={["1"]} onChange={onChange}>
          <Panel header="About Ghoshak?" key="1" className="pane-header-text">
            <p>
              <div className="fqs-answer-test">
                <GrSubtract className="qs-heading-icon-sub" />
                Ghoshak is a Digital platform for managing, and growing your
                online business. Create a website, add products, manage
                inventory, accept online orders, and much more. It's the
                simplest way to take your company to the next level.
              </div>
            </p>
          </Panel>
          <Panel
            header="How to purchase the product from Ghoshak?"
            key="2"
            className="pane-header-text"
          >
            <p>
              <div className="fqs-answer-test">
                <GrSubtract className="qs-heading-icon-sub" />
                Ghoshak app is completely free to use. You can try a 6-day free
                trial of Ghoshak application to gain access to premium features.
                After the trial period expires, you can select a plan & pricing
                option that best suits your company needs.
              </div>
            </p>
          </Panel>
          <Panel
            className="pane-header-text"
            header="How to create a website?"
            key="3"
          >
            <p>
              <div className="fqs-answer-test">
                <GrSubtract className="qs-heading-icon-sub" /> Using the Ghoshak
                Business App, you can create your own website. Enter the
                information you want to show and upload image. Create your
                website by selecting a design.
              </div>
            </p>
          </Panel>
          <Panel
            className="pane-header-text"
            header="Do you have a billing option in Ghoshak?"
            key="4"
          >
            <p>
              <div className="fqs-answer-test">
                <GrSubtract className="qs-heading-icon-sub" /> We have Point of
                sale(POS) as well as mobile billing, and it will work online.
              </div>
            </p>
          </Panel>
          <Panel
            className="pane-header-text"
            header="Do you have a digital marketing in Ghoshak?"
            key="5"
          >
            <p>
              <div className="fqs-answer-test">
                <GrSubtract className="qs-heading-icon-sub" /> Yes, we will do
                digital marketing for your organisation.
              </div>
            </p>
          </Panel>
          <Panel
            className="pane-header-text"
            header="Can the software be upgraded with new features in the future?"
            key="6"
          >
            <p>
              <div className="fqs-answer-test">
                <GrSubtract className="qs-heading-icon-sub" /> Of course! We are
                always eager to improve, upgrade, and expand our work.
              </div>
            </p>
          </Panel>
          <Panel
            className="pane-header-text"
            header="How to connect with Ghoshak?"
            key="7"
          >
            <p>
              <div className="fqs-answer-test">
                <GrSubtract className="qs-heading-icon-sub" /> Send a text
                message to +919150772602 or call to +917401066066. Our telesales
                team expert will contact you shortly to set up a personalised
                demo for your company.
              </div>
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}
